import React, { Component, useState, useEffect } from "react"
import Header1 from "../../components/header1/header1"
import Paragraph from "../../components/paragraph/paragraph"
import Nav from "../../components/navbar/navbar"
import Grid from "@material-ui/core/Grid"
import Footer from "../../components/footer/footer"
import Hidden from "@material-ui/core/Hidden";
import Carousel from "react-elastic-carousel";

export default function Results() {
  var size = useWindowSize()
  const height = size.height

  return (
    <div>
      <Nav selected="Projects" />
      <div style={{ width: "100%", height: "30vh", position: "relatives" }}>
        <img
          src={require("./../../assets/hudsonHeader.jpg")}
          style={{
            height: "30vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "30vh",
            display: "flex",
          }}
        >
          <Grid
            container
            style={{
              alignItems: "center",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Header1
                style={{
                  color: "white",
                  fontSize: '5vh',
                }}
              >
                Hudson Farms
              </Header1>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid container>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>

          <Grid
            container
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Paragraph style={{margin:0}}>
                <b>Type:</b> Improved Forest Management
                <br />
                <br />
                <b>Standard:</b> American Carbon Registry
                <br />
                <br />
              </Paragraph>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paragraph style={{margin:0}}>
                <b>Area:</b> 3,181 acres
                <br />
                <br />
                <b>Developer:</b> Bluesource
                <br />
                <br />
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paragraph style={{margin:0}}>
                <b>Location:</b> Northern New Jersey
              </Paragraph>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Summary Section
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Header1
                  style={{
                    color: "#22422B",
                  }}
                >
                  Summary
                </Header1>
                <Paragraph>
                  Hudson Farm is made of several parcels of land, joined
                  together to create a conservation park.
                  <br />
                  <br />
                  As an Improved Forest Management project, some tiber
                  harvesting occurs but it has committed to increasing forest
                  carbon stocks above the regional common practice where we see
                  much more aggressive harvesting of trees, decreasing the
                  quality of habitat and the forest’s ability to remove carbon.
                </Paragraph>
              </Grid>
              <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
              <Hidden smDown>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <img
                  src={require("./../../assets/hudsonSummary.jpg")}
                  style={{
                    width: "100%",
                    maxHeight: "40vh",
                    objectFit: "cover",
                  }}
                />
                <Paragraph>
                  The project mandates the forest is properly managed to promote
                  a healthy and resilient forest.
                </Paragraph>
              </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Summary Section
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Header1
                  style={{
                    color: "#22422B",
                  }}
                >
                  Co-Benefits
                </Header1>
                <Paragraph>
                  The Hudson Farms project protects forests used by deer, black
                  bear, foxes, migratory birds, and many other species. This is
                  particularly important because the forest is surrounded on
                  three sides by urban areas, indicating this forest was under
                  significant development pressure. It also was the location
                  where adventurers first came up with the idea of the
                  Appalachian Trail. Today it acts as the hub of several
                  community initiatives to encourage interaction and learning
                  with the forest.
                </Paragraph>
              </Grid>
              <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
              <Hidden smDown>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <img
                  src={require("./../../assets/hudsonFox.jpg")}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Paragraph>
                  The project mandates the forest is properly managed to promote
                  a healthy and resilient forest.
                </Paragraph>
              </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/*
          Bottom Images
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          <Grid
            container
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              paddingTop: 20,
              paddingBottom: 40,
            }}
          >
            <Hidden smDown>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingRight: 20 }}
            >
              <img
                src={require("./../../assets/hudsonBottom1.png")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <img
                src={require("./../../assets/hudsonBottom2.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: 20 }}
            >
              <img
                src={require("./../../assets/hudsonBottom3.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            </Hidden>
            <Hidden mdUp>
            <Carousel
                showArrows={false}
                pagination={false}
                style={{ height: "40vh" }}
              >
                <img
                  src={require("./../../assets/hudsonSummary.jpg")}
                  style={{
                    width: "100%",
                    maxHeight: "40vh",
                    objectFit: "cover",
                  }}
                />
                <img
                src={require("./../../assets/hudsonBottom2.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
              <img
                src={require("./../../assets/hudsonBottom3.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
              </Carousel>
            </Hidden>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
        </Grid>
      </div>
      <Footer />
    </div>
  )
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
